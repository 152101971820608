import {
  AppBar,
  Box,
  MenuItem,
  Stack,
  styled,
  Toolbar,
  Typography,
  Menu,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState } from "react";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";

// ---------------------------------------------------------
// STYLING
// ---------------------------------------------------------

// Hide links on smaller screens
const Links = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
// Download Button sytle
const DownloadButton = styled("Button")({
  border: "1px solid #4936B3",
  borderRadius: "24px",
  padding: "10px 20px",
  backgroundColor: "white",
  gap: "10px",
});
// Hide hamburger menu on big screens
const LinksBox = styled("Box")(({ theme }) => ({
  display: "block",
  gap: "10px",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const NavBarLandPage = () => {
  const [open, setOpen] = useState(false);

  return (
    <AppBar position="sticky" color="" sx={{ boxShadow: "none" }}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Link>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img width="70px" src={logo} alt="Logo" />
            <Typography
              variant="h5"
              sx={{ fontWeight: "900", color: "#4936b3" }}
            >
              Incircled
            </Typography>
          </Box>
        </Link>

        <Links>
          <Stack direction="row" spacing={3} alignItems="center">
            <Link to="/services">Our Services</Link>
            <Link to="/about-us">About Us</Link>
            <Link>Partners</Link>
            <DownloadButton variant="outlined">
              <Link>Download App</Link>
            </DownloadButton>
          </Stack>
        </Links>

        <LinksBox>
          <IconButton size="large" onClick={(e) => setOpen(true)}>
            <MenuIcon />
          </IconButton>
        </LinksBox>
      </Toolbar>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        open={open}
        onClose={(e) => setOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem>Our Services</MenuItem>
        <MenuItem>About Us</MenuItem>
        <MenuItem>Parters</MenuItem>
        <MenuItem>Download App</MenuItem>
      </Menu>
    </AppBar>
  );
};

export default NavBarLandPage;
