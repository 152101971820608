import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#4936B3",
    },
    secondary: {
      main: "#251B5A",
    },
    nav: {
      main: "#FFF",
    },
  },
});