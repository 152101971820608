import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ fontSize: "30px" }}>404 Page Not found</Box>
      <Box>
        <p>
          Didn't find what you were looking for ? <br />
        </p>
      </Box>
      <Link to="/">Home Page</Link>
    </Box>
  );
};

export default NotFound;
