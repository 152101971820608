import NavBarLandPage from '../components/NavBarLandPage'
import {
  Box,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import Frame41 from "../assets/Frame 41.png";
import MaskGroup from "../assets/Mask group.png";
import MaskGroup1 from "../assets/Mask group (1).png";
import MaskGroup2 from "../assets/Mask group (2).png";
import MaskGroup3 from "../assets/Mask group (3).png";
import MaskGroup4 from "../assets/Mask group (4).png";
import MaskGroup5 from "../assets/Mask group (5).png";
import Frame42 from "../assets/Frame 42.png";
import LearnSkill from "../assets/learn_skills.png";
import FindVisa from "../assets/find_visa.png";
import Partner1 from "../assets/Partner1.png";
import Partner2 from "../assets/Partner2.png";
import Partner3 from "../assets/Partner3.png";
import Partner4 from "../assets/Partner4.png";
import linkedin from "../assets/linkedin.png";
import instagram from "../assets/instagram.png";
import email from "../assets/email.png";
import phone from "../assets/phone.png";
import LeftVector from "../assets/LeftVector.png";
import RightVector from "../assets/RightVector.png";
import { Link } from "react-router-dom";
import thesis from "../assets/Thesis-pana.png";

const PrimaryButton = styled("Button")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: "200px",
  height: "60px",
  padding: "10px",
  borderRadius: "24px",
  color: "white",
  boxShadow: "none",
  border: "none",
  [theme.breakpoints.down("sm")]: {
    width: "50%",
  },
}));

const SecondaryButton = styled("Button")(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: "white",
  width: "159px",
  padding: "10px",
  border: "1px solid #4936B3",
  borderRadius: "24px",
  boxShadow: "none",
  [theme.breakpoints.down("sm")]: {
    width: "50%",
  },
}));

const StyledTypography = styled("Typography")({
  color: "#251B5A",
  lineHeight: "1.5em",
  fontSize: "20px",
});


const Landpage = () => {
  return (
    <>
      <NavBarLandPage />

      <Stack
        flexWrap="wrap"
        marginTop={5}
        alignItems="center"
        justifyContent="space-around"
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        padding={{ xs: 3 }}
      >
        <Box>
          <Typography
            sx={{ fontWeight: "900" }}
            variant="h3"
            component="h1"
            color="#4936B3"
            className="heading1"
          >
            SECURE A JOB WITH YOUR
          </Typography>
          <Typography
            sx={{ fontWeight: "900", marginBottom: "30px" }}
            variant="h4"
            component="h1"
            color="#4936B3"
            className="heading1"
          >
            RESEARCH PAPERS & PROJECTS
          </Typography>

          <Typography
            className="subheading"
            sx={{ fontSize: "30px", marginBottom: "5rem" }}
          >
            Connecting Recruiters to Diverse <br />
            College Students & Entry Level <br />
            Professionals
          </Typography>

          <Stack
            direction={{ xs: "row", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <PrimaryButton variant="contained">Get Started</PrimaryButton>
            <SecondaryButton variant="outlined">
              <Link>Log in</Link>
            </SecondaryButton>
          </Stack>
        </Box>
        <img
          className="img-fluid"
          width="598px"
          src={thesis}
          alt="Thesis Icon"
        />
      </Stack>

      {/* <BuildAmazing /> */}
      <Stack
        flexWrap="wrap"
        marginTop={10}
        alignItems="center"
        justifyContent="space-around"
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        padding={{ xs: 3 }}
      >
        <img
          className="img-fluid"
          width="598px"
          src={Frame41}
          alt="Thesis"
        />

        <Box>
          <StyledTypography>
            Build an{" "}
            <span style={{ fontWeight: "900", fontSize: "20px" }}>amazing</span>{" "}
            , academic
            <br />& professional networking with
            <span style={{ fontWeight: "900", fontSize: "20px" }}>
              {" "}
              INCIRCLED
            </span>
          </StyledTypography>

          <Typography
            sx={{
              letterSpacing: "5px",
              fontSize: "20px",
              fontWeight: "500",
              margin: "30px 0",
            }}
          >
            Mobile version will soon <br />
            be available on Google <br />
            Play Store.
          </Typography>

          <Typography sx={{ fontWeight: "800", fontSize: "20px" }}>
            Watch this space !!!
          </Typography>
        </Box>
      </Stack>

      {/* WHAT YOU CAN DO */}
      <Stack flexWrap="wrap" marginTop={10} spacing={5}>
        <Box sx={{ textAlign: "center" }}>
          <Typography
            sx={{ fontWeight: "900", color: "#251B5A", marginBottom: "40px" }}
            variant="h3"
            component="h1"
          >
            What you can do...
          </Typography>

          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            padding={{ xs: 3, md: 5 }}
            columns={{ xs: 6, sm: 6, md: 12 }}
            position="relative"
          >
            <Grid item xs={6} sm={3} md={4}>
              <img
                src={MaskGroup}
                alt="Image1"
                style={{ marginBottom: "25px" }}
              />
              <p
                style={{
                  backgroundColor: "rgba(219, 215, 240, 0.15)",
                  padding: "20px",
                  wiidth: "10px",
                }}
              >
                Find Circles you <br /> identify with
              </p>
            </Grid>
            <Grid item xs={6} sm={3} md={4}>
              <img
                src={MaskGroup1}
                alt="Image2"
                style={{ marginBottom: "25px" }}
              />
              <p
                style={{
                  backgroundColor: "rgba(219, 215, 240, 0.15)",
                  padding: "20px",
                  wiidth: "10px",
                }}
              >
                Help recruiters identify <br /> diversified talents
              </p>
            </Grid>
            <Grid item xs={6} sm={3} md={4}>
              <img
                src={MaskGroup2}
                alt="Image2"
                style={{ marginBottom: "40px" }}
              />
              <p
                style={{
                  backgroundColor: "rgba(219, 215, 240, 0.15)",
                  padding: "20px",
                  wiidth: "10px",
                }}
              >
                Improve your skills with <br /> Career Mentorship
              </p>
            </Grid>
            <Grid item xs={6} sm={3} md={4}>
              <img
                src={MaskGroup3}
                alt="Image4"
                style={{ marginBottom: "25px" }}
              />
              <p
                style={{
                  backgroundColor: "rgba(219, 215, 240, 0.15)",
                  padding: "20px",
                  wiidth: "10px",
                }}
              >
                Join the setspace <br /> conversations
              </p>
            </Grid>
            <Grid item xs={6} sm={3} md={4}>
              <img
                src={MaskGroup4}
                alt="Image5"
                style={{ marginBottom: "25px" }}
              />
              <p
                style={{
                  backgroundColor: "rgba(219, 215, 240, 0.15)",
                  padding: "20px",
                  wiidth: "10px",
                }}
              >
                Find jobs and <br /> internships
              </p>
            </Grid>
            <Grid item xs={6} sm={3} md={4}>
              <img
                src={MaskGroup5}
                alt="Image6"
                style={{ marginBottom: "25px" }}
              />
              <p
                style={{
                  backgroundColor: "rgba(219, 215, 240, 0.15)",
                  padding: "20px",
                  wiidth: "10px",
                }}
              >
                Locate events and <br /> conferences
              </p>
            </Grid>

            <img className="left_vector" src={LeftVector} alt="vector_image" />
            <img
              className="right_vector"
              src={RightVector}
              alt="vector_image"
            />
          </Grid>
        </Box>
      </Stack>

      {/* SPICE UP */}
      <Stack
        flexWrap="wrap"
        marginTop={10}
        alignItems="center"
        justifyContent="space-around"
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        padding={{ xs: 3 }}
      >
        <img
          className="img-fluid"
          src={Frame42}
          alt="Frame42"
          style={{ width: "585px" }}
        />
        <Box>
          <Typography variant="h4" component="h1" color="#4936B3">
            SPICE UP THE CULTURE AT YOUR <br />
            WORK PLACE <br /> <br />
          </Typography>

          <Typography
            className="subheading"
            variant="p"
            component="p"
            sx={{ marginBottom: "50px", fontSize: "20px" }}
          >
            Incircled is an all inclusive <br />
            professional social media networking <br />
            platform built for college students <br />
            entry professionals, promoting, <br />
            diversity, equity and inclusion.
          </Typography>

          <PrimaryButton variant="contained">Read more</PrimaryButton>
        </Box>
      </Stack>

      {/* LEARN SKILLS */}
      <Stack
        flexWrap="wrap"
        marginTop={10}
        alignItems="center"
        justifyContent="space-around"
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        padding={{ xs: 3 }}
      >
        <Box>
          <Typography variant="h4" component="h1" color="#4936B3">
            LEARN SKILLS <br /> <br />
          </Typography>

          <Typography
            variant="p"
            component="p"
            sx={{ marginBottom: "50px", fontSize: "20px" }}
          >
            Join our learning space for on- <br /> demand skills and acquire
            practical
            <br /> experience in real-time projects to <br />
            launch your career while still in college
            <br />
            -Access Equal Learning Opportunities <br />
            in various industries from tech to <br />
            finance to HR.
          </Typography>

          <PrimaryButton variant="contained">Read more</PrimaryButton>
        </Box>
        <img className="img-fluid" src={LearnSkill} alt="Learn_skill_image" />
      </Stack>

      {/* FIND VISA */}
      <Stack
        flexWrap="wrap"
        marginTop={10}
        alignItems="center"
        justifyContent="space-around"
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        padding={{ xs: 3 }}
      >
        <img className="img-fluid" src={FindVisa} alt="Find visa" />
        <Box>
          <Typography variant="h4" component="h1" color="#4936B3">
            FIND VISA SPONSORED JOBS
            <br /> <br />
          </Typography>

          <Box textAlign="justify">
            <Typography
              variant="p"
              component="p"
              sx={{ marginBottom: "50px", fontSize: "20px" }}
            >
              Dujj helps international students find
              <br /> sorted companies and recruiters with
              <br /> visa sponsored jobs. We take <br />
              international students at heart and
              <br />
              work to enable a stress-free <br />
              experience while studying abroad <br />
              Need an
            </Typography>
          </Box>
          <PrimaryButton variant="contained">Read more</PrimaryButton>
        </Box>
      </Stack>

      {/* MEET OUR PARTNERS */}
      <Stack flexWrap="wrap" marginTop={10} spacing={5}>
        <Box sx={{ textAlign: "center" }}>
          <Typography
            sx={{ fontWeight: "900", color: "#251B5A", marginBottom: "40px" }}
            variant="h4"
            component="h1"
            id="partners"
          >
            Meet our partners
          </Typography>

          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            padding={{ xs: 3, md: 5 }}
            columns={{ xs: 6, sm: 6, md: 12 }}
          >
            <Grid item xs={6} sm={3} md={3}>
              <img className="img-fluid" src={Partner1} alt="Image1" />
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <img className="img-fluid" src={Partner2} alt="Image2" />
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <img className="img-fluid" src={Partner3} alt="Image3" />
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <img className="img-fluid" src={Partner4} alt="Image4" />
            </Grid>
          </Grid>

          <Link to="/">View all partners</Link>
        </Box>
      </Stack>

      {/* SUBSCRIBE TO OUR NEWS LETTERS */}
      <Stack
        flexWrap="wrap"
        marginTop={10}
        alignItems="center"
        justifyContent="space-around"
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        padding={{ xs: 3 }}
      >
        <Box>
          <Typography
            sx={{ fontWeight: "900", color: "#251B5A", marginBottom: "40px" }}
            variant="h4"
            component="h1"
            id="partners"
          >
            Subscribe to our newsletter.
          </Typography>
          <Typography variant="p">
            Enter your email address and be the first to know about updates
          </Typography>
        </Box>
      </Stack>

      <div className="newsletter">
        <form action="">
          <input type="email" placeholder="Enter your email address" />
          <input type="submit" value="Subscribe now" />
        </form>
      </div>
      {/* END SUBSCRIBE TO OUR NEWS LETTERS */}

      {/* FOOTER */}
      <Stack flexWrap="wrap" marginTop={10} spacing={5}>
        <Box sx={{ textAlign: "center" }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            padding={{ xs: 3, md: 5 }}
            columns={{ xs: 6, sm: 6, md: 12 }}
          >
            <Grid item xs={6} sm={3} md={3}>
              <Typography
                marginBottom="20px"
                fontWeight="900"
                variant="h5"
                component="h1"
              >
                COMPANY
              </Typography>
              <Box lineHeight="50px" display="flex" flexDirection="column">
                <Link>About us</Link>
                <Link>Careers</Link>
              </Box>
            </Grid>

            <Grid item xs={6} sm={3} md={3}>
              <Typography
                marginBottom="20px"
                fontWeight="900"
                variant="h5"
                component="h1"
              >
                LEGAL
              </Typography>
              <Box lineHeight="50px" display="flex" flexDirection="column">
                <Link>Terms & Conditions</Link>
                <Link>Privacy Policy</Link>
              </Box>
            </Grid>

            <Grid item xs={6} sm={3} md={3}>
              <Typography
                marginBottom="20px"
                fontWeight="900"
                variant="h5"
                component="h1"
              >
                CONTACT US
              </Typography>
              <Box lineHeight="50px" display="flex" flexDirection="column">
                <Link>
                  {" "}
                  <img src={email} alt="email_logo" /> hello@incircled.com
                </Link>
                <Link>
                  <img src={phone} alt="phone_logo" /> +554 235 425 420
                </Link>
              </Box>
            </Grid>

            <Grid item xs={6} sm={3} md={3}>
              <Typography
                marginBottom="20px"
                fontWeight="900"
                variant="h5"
                component="h1"
              >
                FOLLOW US
              </Typography>
              <Box display="flex" justifyContent="center">
                <IconButton aria-label="" size="">
                  <img src={linkedin} alt="Linkedin_image" />
                </IconButton>
                <IconButton aria-label="" size="">
                  <img src={instagram} alt="Instagram_image" />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Stack>
      {/* END FOOTER */}
    </>
  );
}

export default Landpage